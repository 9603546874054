.Row {
  padding: 0 15px;
  margin-top: 30px;
  @media (max-width: 1024px) {
    margin: 0;
  }
}
.filterTableRow {
  margin: 15px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.filterContent {
  margin-top: 20px;
  justify-content: center;
  font-size: 16px;

  display: flex !important;
  width: 98.5%;
  height: auto;
  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
  }
  .filterType {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: left;
    align-items: center;

    @media (max-width: 1024px) {
      width: 100%;
    }
    b {
      margin-right: 5px;
      text-align: right;
    }
    span {
      color: black;
      font-size: 15px;
    }
  }
  .siteDropdown {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
    > div {
      > div {
        display: flex;
        border: 1px solid #fff !important;
        border-radius: 5px !important;
        align-items: center;
        justify-content: center;
        background-color: #fff !important;
      }
    }
  }
  .timeContent {
    padding: 15px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      width: 100%;
    }
    .ant-picker-focused {
      box-shadow: none !important;
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    > div {
      background-color: #fff;
      border-radius: 5px;
      &:hover {
        box-shadow: none;
        outline: none;
        border-color: #d9d9d9;
        // border-bottom: 2px solid;
      }
      input {
        margin-bottom: 0;
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
  .applyBtn {
    background-color: #1e263d;
    display: flex;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    border-radius: 5px;
    padding: 10px 20px;
    height: auto;
    .rightOutLine {
      margin-top: 3px;
    }

    &:hover {
      color: #fff;
      background-color: #1e263d;
      //  border: 0px ;
    }
    &:focus {
      color: #fff !important;
      background-color: #1e263d !important;
    }
  }
}

.noDataText {
  // justify-content: center;
  text-align: center;
  p {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    color: #1e263d;
  }
}

.selectDropdown {
  > div {
    background-color: #fff !important;
    border-radius: 5px !important;
    padding: 3px 0;
  }
}

.spinnerRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
.mainTableDiv {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  max-height: 500px !important;
  margin-top: 30px;
  .tableMain {
    width: 100%;

    .heading {
      text-align: center;
    }
    .theadMain {
      position: sticky !important;
      top: 0 !important;
    }
    .headMain {
      background-color: #1e263d;
      color: #fff;
      margin-bottom: 20px;
      > th {
        padding: 10px;
        text-align: center;
      }
    }
    .trMain {
      border: 1px solid #fff;
      height: auto;
      margin-top: 5px;

      background-color: #d7dbe7;
      .tdMain {
        text-align: center;
        padding: 10px;
        width: 50%;
        border: 1px solid #fff;
      }
    }
  }
}
.noRecord {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.noRecordWorking {
  width: 100% !important;
  height: 70vh !important;
  display: flex !important;
  align-items: center !important;
}
.CriticalFirstModalWidth {
  width: 75% !important;
}
.critModalContent {
  height: 400px;
  overflow: auto;
  overflow-x: hidden;
  .xdrLvlSecondTable {
    width: 100%;
    display: inline-block;
    .xdrSecondlvlTr {
      background-color: #dee2e6;
      border-bottom: 5px solid white;
    }
    .xdrLvlSecondTableBody {
      display: block;
      tr {
        display: block;
        td {
          display: block;
        }
      }
    }
    .fourthModalColor {
      background-color: #d7dbe7 !important;
      color: black !important;
      padding: 20px !important;
      border-radius: 8px;
      margin: 10px;
    }
  }
  .loaderContent {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > b {
    width: 80%;
  }
  > span {
    width: 20%;
    text-align: right;
  }
  .thirdTitle {
    > span {
      display: flex;
      b {
        min-width: 10%;
        margin: 0;
      }
      p {
        margin: 0 0 0 4px;
      }
    }
  }
}
.hostListInfoTable {
  border: 1px solid;
  width: 100%;
  margin-top: 30px;
  > thead {
    > tr {
      border: 1px solid;
      > th {
        padding: 5px;
        text-align: center;
        border: 1px solid;
      }
    }
  }
  > tbody {
    > tr {
      border: 1px solid white;
      background-color: #d7dbe7;
      > td {
        padding: 5px;
        text-align: center;
        border: 1px solid white;
      }
      > td:first-child {
        color: blue;
        font-weight: 500;
      }
    }
  }
}
