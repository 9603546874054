.tableMain {
    width: 100%;

    .heading {
      text-align: center;
    }
    .theadMain {
      position: sticky !important;
      top: 0 !important;
    }
    .headMain {
      background-color: #1e263d;
      color: #fff;
      margin-bottom: 20px;
      > th {
        padding: 10px;
        text-align: center;
      }
    }
    .trMain {
      border: 1px solid #fff;
      height: auto;
      margin-top: 5px;

      background-color: #d7dbe7;
      .tdMain {
        text-align: center;
        padding: 10px;
        width: 50%;
        border: 1px solid #fff;
      }
    }
  }
  .color{
    text-align: center;
    padding: 10px;
    width: 50%;
    border: 1px solid #fff;
    color: blue;
    cursor: pointer;
  }