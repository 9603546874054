.graphMain {
  border: 1px solid;
  width: 100% !important;
  height: 80vh !important;
  > div {
    > svg {
      display: inline-block;
      width: 100% !important;
      height: 80vh !important;
      >g:first-child{
        transform: translate(311.5, 342.5) scale(0.5, 0.5) !important; 
      }
    }
  }
}
.graphModalTable {
  width: 100%;
  > tbody {
    > tr {
      > td,
      > th {
        background-color: #1e263d;
        padding: 5px 0;
        color: #fff;
      }
      > th {
        text-align: left;
        padding-left: 10px;
      }
    }
    .bottomradiusDiv {
      border-radius: 0 0 0 10px !important;
    }
    .bottomRightDiv {
      border-radius: 0 0 10px 0 !important;
    }
  }
}

.filterContent {
  margin: 20px 0 24px 24px;
  font-size: 16px;
  gap: 10px; 
  width: 98.5%;
  height: auto;
  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
  }
  .filterType {
    width: 20%;
    padding: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    @media (max-width: 1440px) {
      width: 30%;
    }
    b {
      margin-right: 5px;
      text-align: right;
    }
    span {
      color: black;
      font-size: 15px;
    }
  }
  .siteDropdown {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
    > div {
      > div {
        display: flex;
        border: 1px solid #fff !important;
        border-radius: 5px !important;
        align-items: center;
        justify-content: center;
        background-color: #fff !important;
      }
    }
  }
  .timeContent {
    width: 45%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      width: 100%;
    }
    .ant-picker-focused {
      box-shadow: none !important;
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    > div {
      background-color: #fff;
      border-radius: 5px;
      &:hover {
        box-shadow: none;
        outline: none;
        border-color: #d9d9d9;
      }
      input {
        margin-bottom: 0;
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
}
.filterType {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
  b {
    margin-right: 5px;
    text-align: right;
  }
  span {
    color: black;
    font-size: 15px;
  }
}
.siteDropdown {
  border: 1px solid;
  border-radius: 5px;
  background: #1e263d;
  color: #fff;
  padding: 10px;
  > div {
    > div {
      display: flex;
      border: 1px solid #fff !important;
      border-radius: 5px !important;
      align-items: center;
      justify-content: center;
      background-color: #fff !important;
    }
  }
}
.selectDropdown {
  > div {
    background-color: #fff !important;
    border-radius: 5px !important;
    padding: 3px 0;
  }
}
.idDivMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e263d;
  border: 1px solid white;
  border-radius: 10px 10px 0 0;
  color: #fff;
  padding: 10px;
}
