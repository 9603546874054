.sellerCard {
  margin-top: 30px !important;
  border-radius: 10px !important;
  box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.1);
  > div {
    padding: 25px !important;
    .bestSeller {
      display: flex;
      justify-content: center;
      > p {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
    }
    .profileDiv {
      margin: 15px 0;
      .sellerDetails {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .imgDiv {
          .imgProfile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > img {
              width: 50px;
              border-radius: 50%;
              height: 50px;
            }
          
          }
        }
      }
      .viewProfile {
        > p {
          color: #43aab2;
          font-weight: 600;
        }
      }
    }
  }
}
