.Row {
  padding: 0 15px;
  margin-top: 30px;
  @media (max-width: 1024px) {
    margin: 0;
  }
}
.aTagColor {
  color: blue;

  &:hover {
    color: blue;
  }
}

.filterTableRow {
  margin: 15px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid;
    padding: 5px;
    border-bottom: 0 !important;
  }
  > div:nth-child(1) {
    width: 70%;
    text-align: center;
  }
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }
}
.signatureHeader {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }
  > div:nth-child(1) {
    width: 80%;
  }
  > div:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: center;
  }
}
.tableBg {
  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3) {
    background-color: #f2f2f2;
  }
}
.modalDetail {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #000;
    padding: 5px;
  }

  > div:nth-child(1) {
    width: 70%;
    text-align: center;
  }
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }

  > div:nth-child(3) {
    display: flex;

    > span {
      text-align: right;
    }
  }
  > a,
  span {
    // border-radius: 10px;
    color: #000;
    // background-color: #f0f0f0;
    &:hover {
      color: #000;
    }
  }
  > a {
    width: 75%;
  }
  > b {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
  }
  > span {
    width: 13%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.signatureAlert {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }

  > div:nth-child(1) {
    width: 80%;
  }
  > div:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    > span {
      text-align: right;
    }
  }
  > a,
  span {
    // border-radius: 10px;
    color: #000;
    // background-color: #f0f0f0;
    &:hover {
      color: #000;
    }
  }
  > a {
    width: 75%;
  }
  > b {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
  }
  > span {
    width: 13%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.critSecAlert {
  .headerContent {
    display: flex;
    text-align: center;
    justify-content: center;
    > span {
      border: 1px solid #000;
      width: 33.33%;
      padding: 5px;
    }
  }

  .criticalShotsModal {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    > span {
      width: 33.33%;
      border: 1px solid;
      padding: 5px;
    }
  }
  .criticalShotsModalBg {
    > span {
      background-color: #f2f2f2;
    }
  }
}
.siteInfoModal {
  margin-top: 30px;
}
.critThirdAlertModal {
  // border: 1px solid;
  border-radius: 10px;
  > div {
    background-color: #7099cb;
    margin: 10px;
    border: 0;
    padding: 5px;
    color: #fff;
    p {
      margin-top: 15px !important;
    }
    b {
      border: 1px solid;
      padding: 5px;
      border-radius: 5px;
    }
  }
}
.critThirdAlertModalBg {
  background-color: #f2f2f2;
}
.dateTimeStyle {
  cursor: pointer;
}

.filterContent {
  margin-top: 20px;
  font-size: 18px;
  gap: 10px;
  display: flex !important;
  width: 98.5%;
  height: auto;
  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
  }

  .timeContent {
    width: 45%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      width: 100%;
    }
    .ant-picker-focused {
      box-shadow: none !important;
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    > div {
      background-color: #fff;
      border-radius: 5px;
      &:hover {
        box-shadow: none;
        outline: none;
        border-color: #d9d9d9;
        // border-bottom: 2px solid;
      }
      input {
        margin-bottom: 0;
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
}

.critModalContent {
  overflow: auto;
  width: 100%;
  max-width: 100;
  .loaderContent {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > b {
    width: 80%;
  }
  > span {
    width: 20%;
    text-align: right;
  }
  .thirdTitle {
    > span {
      display: flex;
      b {
        min-width: 10%;
        margin: 0;
      }
      p {
        margin: 0 0 0 4px;
      }
    }
  }
}

.buttonRow {
  margin: 30px 0;
  display: flex;
  align-items: center;
  .backButton {
    padding: 10px 20px;
    height: auto;
    background-color: #1e263d;
    color: #fff;
    width: 90%;
    font-weight: 800;
    border-radius: 15px;
    border: 0;
    &:hover {
      background-color: #1e263d;
      font-weight: 800;
      color: #fff;
      border: 0;
    }
  }
  .infoButton {
    margin: 0 10px;
    > svg {
      width: 30px;
      height: 31px;
    }
  }
}

.mainTable {
  margin-top: 30px;
  border: 2px solid #b4b1ba;
  width: 100%;
  border-radius: 5px !important;
  > thead,
  tbody {
    > tr {
      border: 2px solid #b4b1ba;
      text-align: center;
      > th {
        border: 2px solid #fff;
        padding: 15px;
        background-color: #1e263d;
        color: #fff;
      }
      > td {
        background-color: #d7dbe7;
        cursor: pointer;
        color: #000;
        border: 2px solid #fff;
        font-weight: 600;
        > span {
          color: #000;
        }
      }
    }
  }
  > thead {
    > tr {
      > th:nth-child(1) {
        padding: 15px 50px;
      }
    }
  }
}
.breadcrumbMain {
  margin: 65px 0 10px 0 !important;
}
.surucataSecLevelTable {
  border: 1px solid;
  width: 100%;
  > thead {
    > tr {
      border: 1px solid;
      > th {
        padding: 5px;
        text-align: center;
        border: 1px solid;
      }
    }
  }
  > tbody {
    > tr {
      border: 1px solid;
      > td {
        padding: 5px;
        text-align: center;
        border: 1px solid black;
      }
      > td:first-child {
        color: blue;
        font-weight: 500;
      }
    }
  }
}

.hostListInfoTable {
  border: 1px solid;
  width: 100%;
  > thead {
    > tr {
      border: 1px solid;
      > th {
        padding: 5px;
        text-align: center;
        border: 1px solid;
      }
    }
  }
  > tbody {
    > tr {
      border: 1px solid;
      > td {
        padding: 5px;
        text-align: center;
        border: 1px solid black;
      }
      > td:first-child {
        color: blue;
        font-weight: 500;
      }
    }
  }
}
.leftTr {
  text-align: left !important;
  word-wrap: break-word !important;
  max-width: 800px;
  min-width: 0px;
}
.rightTr {
  text-align: right !important;
}
.suricataTable {
  background-color: #f2f2f2;
}
.secondLabel {
  margin: 10px 0;
}

.thirdTableModalWidth {
  min-width: 200px !important;
  max-width: calc(100vw - 200px) !important;
  > :nth-child(2) {
    max-width: 100%;
  }
}
.suricataFirstLvlModal {
  min-width: 200px;
  max-width: calc(100vw - 200px) !important;
  > :nth-child(2) {
    max-width: 100%;
  }
}

.xdrLvlSecondTable {
  width: 100%;
  display: inline-block;
  .xdrSecondlvlTr {
    background-color: #dee2e6;
    border-bottom: 5px solid white;
  }
  .xdrLvlSecondTableBody {
    display: block;
    tr {
      display: block;
      td {
        display: block;
      }
    }
  }
}
.activeColorname {
  color: green;
}
.otherColorName {
  color: red;
}
.fourthModalColor {
  background-color: #d7dbe7 !important;
  color: black !important;
  padding: 20px !important;
  border-radius: 8px;
  margin: 5px;
  > div {
    display: flex;
    justify-content: space-between;
    > div {
      > span {
        display: block;
      }
    }
  }
}
.highlightColor {
  background-color: #1e263d !important;
  color: #fff !important;
  padding: 20px !important;
  border-radius: 8px;
}
.selectSize {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}
.dnsstatPTag{
  margin:5px 0 !important;
}
.filterType {
  width: 100% !important;
  padding: 10px 5px;
  // margin-right: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
  b {
    margin-right: 5px;
    text-align: right;
  }
  span {
    color: black;
    font-size: 15px;
  }
}
.criticalTHirdModalTable {
  background-color: #1d1e24;
  width: 100%;
  color: #fff;
  > tbody,
  thead {
    > tr {
      background-color: #1d1e24;
      th,
      td {
        text-align: center !important;
        border-bottom: 2px solid #333742;
        padding: 5px;
      }

      .buttonTd {
        display: flex;
        justify-content: center;

        > button {
          margin: 5px;
          background-color: #1d1e24;
          color: #fff;
        }
      }
    }
  }
}
.siteDropdown {
  border: 1px solid !important;
  border-radius: 5px;
  background: #1e263d !important;
  color: #fff;
  > div {
    > div {
      display: flex;
      border: 1px solid #fff !important;
      border-radius: 5px !important;
      align-items: center;
      justify-content: center;
      background-color: #fff !important;
    }
  }
}
ol {
  cursor: pointer;
}
.exportBtnModal {
  padding: 10px 40px !important;
  height: auto !important;
  background-color: #1e263d !important;
  color: #fff !important;
  width: 90% !important;
  font-weight: 800 !important;
  border-radius: 15px !important;
  border: 0 !important;
  &:hover {
    background-color: #1e263d !important;
    font-weight: 800 !important;
    color: #fff !important;
    border: 0 !important;
  }
}
