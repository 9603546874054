.mainDiv{
    display: flex;
    >div:first-child{
        margin-right: 20px;
    }    
.progressCard {
    width: 100%;
  
    border-radius: 10px !important;
  
    box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.1);
    @media (max-width: 1024px) {
      margin: 30px 0 !important;
    }
    @media (max-width: 425px) {
      margin: 15px 0 !important;
    }
    > div {
      padding: 0;
  
      .cardMain {
          padding: 10px;
          .salesMain {
              display: flex;
              justify-content: center;
              align-items: center;
              
              .salesTxt {
                  font-size: 20px;
                  letter-spacing: 0.5px;
                  font-weight: 500;
                  margin: 0 !important;
                }
            }
            .alertMain {
                height: 200px;
                font-size: 60px;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
      }
    }
  }
  