.loginMain {
  align-items: center;
  justify-content: center;
  background-color: #1f263d;
  height: 100vh;

  .headerMain {
    text-align: center;
    margin-bottom: 40px;
    > p {
      font-size: 38px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 3px;
    }
  }
  .loginName {
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
      font-size: 26px;
      margin-bottom: 20px;
      font-weight: 600;
      color: #f5f7fa;
    }
  }
  > div {
    padding: 24px 40px;
  }
  .linkButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0px;
    u {
      color: #fff;
    }
  }
  .RegisterButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    p {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0.5px;
    }
  }
  .createAccount {
    justify-content: center;
    margin-top: auto;
  }

  input {
    padding: 10px;
    color: black !important;
    border-radius: 10px;
    background-color: #f5f7fa;
    border: 0;
    &:hover {
      border: 0;
    }
  }
  .buttonMain {
    margin-bottom: 10px;
    > div {
      > div {
        > div {
          .loginButton {
            border-radius: 10px;
            padding: 10px 20px;
            width: 100%;
            color: #1f263d;
            background-color: #aad6ec;
            height: auto;
            border-width: 0px;

            > span {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
