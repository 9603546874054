.buttonStyle {
  background-color: #1e263d !important;
  width: 90% !important;
  margin: 10px !important;
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    background-color: #1e263d !important;
  }
  &:focus {
    color: #fff !important;
    background-color: #1e263d !important;
  }
}
.colorChanges {
  background-color: red !important;
  width: 90% !important;
  margin: 10px !important;
  height: auto !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
    background-color: #1e263d !important;
  }
  &:focus {
    color: #fff !important;
    background-color: red !important;
  }
}
.tableMain {
  width: 100%;
  max-height: 400px !important;
  overflow-y: scroll;
  .heading {
    text-align: center;
  }
  .theadMain {
    position: sticky !important;
    top: 0 !important;
  }
  .headMain {
    background-color: #1e263d;
    color: #fff;
    margin-bottom: 20px;
    > th {
      padding: 10px;
      text-align: center;
    }
  }
  .trMain {
    border: 1px solid #fff;
    height: auto;
    margin-top: 5px;
    background-color: #d7dbe7;
    .tdMain {
      text-align: center;
      padding: 10px;
      border: 1px solid #fff;
    }
  }
  .noRecord {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tabMain {
  margin-top: 30px !important;
  > div:nth-child(1) {
    background-color: #1e263d;
    > div:nth-child(1) {
      > div {
        > div {
          background-color: #1e263d;
          color: #fff;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
          background-color: red !important;
        }
      }
    }
    > div:nth-child(2) {
      > button {
        color: #fff;
      }
    }
  }
  > div:nth-child(2) {
    max-height: 600px !important;
    // overflow-y: scroll !important;
    overflow-x: hidden !important;
    overflow: auto;
    position: relative;
  }
}
.hostListInfoTable {
  width: 100%;
  .suricataTable {
    background-color: #f2f2f2;
  }
}
