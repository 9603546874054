.filterTableRow {
  margin: 15px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.filterContent {
  margin-top: 20px;
  justify-content: center;
  font-size: 16px;

  display: flex !important;
  width: 98.5%;
  height: auto;
  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
  }
  .filterType {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: left;
    align-items: center;

    @media (max-width: 1024px) {
      width: 100%;
    }
    b {
      margin-right: 5px;
      text-align: right;
    }
    span {
      color: black;
      font-size: 15px;
    }
  }
  .siteDropdown {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
    > div {
      > div {
        display: flex;
        border: 1px solid #fff !important;
        border-radius: 5px !important;
        align-items: center;
        justify-content: center;
        background-color: #fff !important;
      }
    }
  }

  .applyBtn {
    background-color: #1e263d;
    display: flex;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    border-radius: 5px;
    padding: 10px 20px;
    height: auto;
    .rightOutLine {
      margin-top: 3px;
    }

    &:hover {
      color: #fff;
      background-color: #1e263d;
      //  border: 0px ;
    }
    &:focus {
      color: #fff !important;
      background-color: #1e263d !important;
    }
  }

  .exportBtn {
    background-color: #1e263d;
    display: flex;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    border-radius: 5px;
    padding: 10px 20px;
    height: auto;
    .rightOutLine {
      margin-top: 3px;
    }

    &:hover {
      color: #fff;
      background-color: #1e263d;
      //  border: 0px ;
    }
    &:focus {
      color: #fff !important;
      background-color: #1e263d !important;
    }
  }
}

.mainTable {
  margin-top: 30px;
  width: 100%;
  border-radius: 5px !important;
  .spinnerContent {
    width: 100%;
    border: 1px solid;
  }
  > thead,
  tbody {
    > tr {
      text-align: center;
      > th {
        border: 2px solid #fff;
        padding: 15px;
        background-color: #1e263d;
        color: #fff;
      }
      > td {
        background-color: #d7dbe7;
        cursor: pointer;
        color: #000;
        border: 2px solid #fff;
        font-weight: 600;
        > span {
          color: #000;
        }
      }
    }
  }
  .networkTitle {
    padding: 15px 0 15px 0;
  }

  .moreInfoBtn {
    background-color: #1e263d;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
  }

  .moreInfoBtnDisable {
    border-radius: 10px;
    font-size: 14px;
  }
}

.loaderContent {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataText {
  justify-content: center;
  text-align: center;
  p {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    color: #1e263d;
  }
}

// styles for the inventory info modal

.critModalContent {
  height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  > b {
    width: 80%;
  }
  > span {
    width: 20%;
    text-align: right;
  }
  .thirdTitle {
    > span {
      display: flex;
      b {
        min-width: 10%;
        margin: 0;
      }
      p {
        margin: 0 0 0 4px;
      }
    }
  }
}

.hostListInfoTable {
  border: 1px solid;
  width: 100%;
  margin-top: 30px;
  > thead {
    > tr {
      border: 1px solid;
      > th {
        padding: 5px;
        text-align: center;
        border: 1px solid;
      }
    }
  }
  > tbody {
    > tr {
      border: 1px solid black;
      > td {
        padding: 5px;
        text-align: center;
        border: 1px solid black;
      }
      > td:first-child {
        color: blue;
        font-weight: 500;
      }
    }
  }
}
.buttonRow {
  margin-top: 20px;
  .backButton {
    padding: 10px 20px;
    width: 100%;
    height: auto;
    background-color: #1e263d;
    color: #fff;
    font-weight: 800;
    border-radius: 15px;
    border: 0;
    &:hover {
      background-color: #1e263d;
      font-weight: 800;
      color: #fff;
      border: 0;
    }
  }
}
.search {
  margin-top: 30px;
  .searchInput {
    padding: 15px !important;
  }
}
.countStyle {
  display: flex !important;
  justify-content: flex-start !important;
  .edrCount {
    width: 200px;
    border: 1px solid #1e263d;
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    margin: 10px;
  }
  .wazuhCount {
    width: 200px;
    border: 1px solid #1e263d;
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    margin: 10px;
  }
}

.activeColorname {
  color: green;
}
.otherColorName {
  color: red;
}
