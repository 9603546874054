.Row {
  padding: 0 15px;
  margin-top: 30px;
  @media (max-width: 1024px) {
    margin: 0;
  }
}
.filterTableRow {
  margin: 15px;
     margin-left: 0 !important;  
      margin-right: 0 !important;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }
  > div:nth-child(1) {
    width: 70%;
  }
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }
}
.signatureHeader {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }
  > div:nth-child(1) {
    width: 80%;
  }
  > div:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: center;
  }
}
.tableBg {
  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3) {
    background-color: #f2f2f2;
  }
}
.modalDetail {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }

  > div:nth-child(1) {
    width: 70%;
  }
  > div:nth-child(2),
  > div:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: center;
  }
  > div:nth-child(2) {
    background-color: #e75f4f;
  }
  > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    > span {
      text-align: right;
    }
  }
  > a,
  span {
    // border-radius: 10px;
    color: #000;
    // background-color: #f0f0f0;
    &:hover {
      color: #000;
    }
  }
  > a {
    width: 75%;
  }
  > b {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
  }
  > span {
    width: 13%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.signatureAlert {
  display: flex;
  justify-content: space-between;
  > div {
    border: 1px solid #dee2e6;
    padding: 5px;
  }

  > div:nth-child(1) {
    width: 80%;
  }
  > div:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    > span {
      text-align: right;
    }
  }
  > a,
  span {
    // border-radius: 10px;
    color: #000;
    // background-color: #f0f0f0;
    &:hover {
      color: #000;
    }
  }
  > a {
    width: 75%;
  }
  > b {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #f0f0f0;
  }
  > span {
    width: 13%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.critSecAlert {
  .headerContent {
    display: flex;
    > span {
      border: 1px solid #dee2e6;
      width: 33.33%;
      padding: 5px;
    }
    > span:nth-child(3) {
      text-align: right;
    }
  }
  .criticalShotsModal {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      width: 33.33%;
      border: 1px solid #dee2e6;
      padding: 5px;
    }
    > span:nth-child(3) {
      text-align: right;
    }
  }
  .criticalShotsModalBg {
    > span {
      background-color: #f2f2f2;
    }
  }
}
.critThirdAlertModal {
  border: 1px solid #dee2e6;
  > div {
    padding: 5px;
    p {
      margin-left: 15px;
    }
  }
}
.critThirdAlertModalBg {
  background-color: #f2f2f2;
}

.filterContent {
  margin-top: 20px;
  justify-content: center;
  font-size: 16px;

  display: flex !important;
  width: 98.5%;
  height: auto;
  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
  }
  .filterType {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1024px) {
      width: 100%;
    }
    b {
      margin-right: 5px;
      text-align: right;
    }
    span {
      color: black;
      font-size: 15px;
    }
  }
  .siteDropdown {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
    > div {
      > div {
        display: flex;
        border: 1px solid #fff !important;
        border-radius: 5px !important;
        align-items: center;
        justify-content: center;
        background-color: #fff !important;
      }
    }
  }
  .timeContent {
    width: 45%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1024px) {
      width: 100%;
    }
    .ant-picker-focused {
      box-shadow: none !important;
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    > div {
      background-color: #fff;
      border-radius: 5px;
      &:hover {
        box-shadow: none;
        outline: none;
        border-color: #d9d9d9;
        // border-bottom: 2px solid;
      }
      input {
        margin-bottom: 0;
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
  .applyBtn {
    background-color: #1e263d;
    display: flex;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    border-radius: 5px;
    padding: 10px 20px;
    height: auto;
    .rightOutLine{
      margin-top: 3px;
    }

    &:hover {
      color: #fff;
      background-color: #1e263d;
      //  border: 0px ;
    }
    &:focus {
      color: #fff !important;
      background-color: #1e263d !important;
    }
  }
}

.exportContent {
  display: flex !important;
  font-size: 18px;
  gap: 10px;

  > div {
    border: 1px solid;
    border-radius: 5px;
    background: #1e263d;
    color: #fff;
    width: 150px;
    margin-top: 10px;
    .radioBtn {
      width: 100% !important;
      padding: 10px;
      > div {
        width: 100% !important;
        background-color: #fff !important;
        border-radius: 5px !important;
        padding: 3px 0;
      }
    }
  }
}
.critModalContent {
  height: 400px;
  overflow: auto;

  > b {
    width: 80%;
  }
  > span {
    width: 20%;
    text-align: right;
  }
  .thirdTitle {
    > span {
      display: flex;
      b {
        min-width: 10%;
        margin: 0;
      }
      p {
        margin: 0 0 0 4px;
      }
    }
  }
}
.noDataText {
  // justify-content: center;
  text-align: center;
  p {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    color: #1e263d;
  }
}

.btnContent {
  margin-top: 30px;
  padding: 5px 20px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid;
  background-color: #d9d9d9;
}
.selectDropdown {
  > div {
    background-color: #fff !important;
    border-radius: 5px !important;
    padding: 3px 0;
  }
}
.buttonRow {
  .backButton {
    padding: 10px 20px;
    width: 100%;
    height: auto;
    background-color: #ababab;
    color: #fff;
    font-weight: 800;
    border-radius: 15px;
    border: 0;
    &:hover {
      background-color: #ababab;
      font-weight: 800;
      color: #fff;
      border: 0;
    }
  }
}

.mainTable {
  margin-top: 30px;
  width: 100%;
  border-radius: 5px !important;
  .spinnerContent {
    width: 100%;
    border: 1px solid;
  }
  > thead,
  tbody {
    > tr {
      text-align: center;
      > th {
        border: 2px solid #fff;
        padding: 15px !important;
        background-color: #1e263d;
        color: #fff;
      }
      > td {
        padding: 15px !important;
        background-color: #d7dbe7;
        cursor: pointer;
        color: #000;
        border: 2px solid #fff;
        font-weight: 600;
        > span {
          color: #000;
        }
      }
    }
  }
  > thead {
    > tr {
      > th:nth-child(1) {
        padding: 15px 50px;
      }
      &:nth-child(1) {
        > th:nth-child(2),
        :nth-child(3) {
          background-color: red !important;
        }
      }
    }
  }
}

.surucataSecLevelTable {
  border: 1px solid;
  width: 100%;
  > thead {
    > tr {
      border: 1px solid;
      > th {
        padding: 5px;
        text-align: center;
        border: 1px solid;
      }
    }
  }
  > tbody {
    > tr {
      border: 1px solid;
      > td {
        padding: 5px;
        text-align: center;
        border: 1px solid black;
      }
      > td:first-child {
        color: blue;
        font-weight: 500;
      }
    }
  }
}


.secondLabel {
  margin: 10px 0;
}

.thirdTableModalWidth {
  width: 1238px !important;
}
.secondLevalModalWidth {
  width: 781px !important;
}
.suricataFirstLvlModal {
  width: 625px !important;
}

.xdrLvlSecondTable {
  width: 100%;
  .xdrSecondlvlTr {
    background-color: #dee2e6;
    border-bottom: 5px solid white;
  }
}
.fourthModalColor {
  background-color: #d7dbe7 !important;
  color: black !important;
  padding: 20px !important;
  border-radius: 8px;
}
.CriticalFirstModalWidth {
  width: 850px !important;
}

.spinnerRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.syncOutlineColor{
  
   margin-left: 10px;
  color: aquamarine !important;
}