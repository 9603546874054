body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imgProfile {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 15px;
}

.detailsButton {
  border: 1px solid #8DD8F7 !important;
  color: #8DD8F7 !important;
  border-radius: 15px !important;
}

.ant-menu-item-selected {
  background-color: #8DD8F7 !important;
  color: black !important;
}

.ant-layout-sider-trigger {
  display: none;
}

.rdt_TableHeadRow {

  font-size: 15px;
  border: 0 !important;
}

.rdt_TableBody .rdt_TableRow {

  padding: 15px 0px !important;
}

.rdt_TableBody {

  max-height: 261px;
  overflow-y: scroll;
}

.ant-menu-item .ant-menu-item-selected {
  background-color: #fff !important;
  color: black !important;
}

.anticon-menu {
  padding: 15px !important;
  position: absolute;
  top: 8px;
  right: -44px;
}
 
.modal-title {
  width: 100%;
  cursor: move;
}
.anticon-menu {
  padding: 15px !important;
  position: absolute;
  top: 8px;
  right: -44px;
}
.modal-header {
  cursor: move;
} 
.react-resizable-handle {
  background-color: #ddd;
  border-radius: 2px;
  border: 1px solid #888;
  height: 400px;
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 675px;
  z-index: 1;
  cursor: nwse-resize;
}
 
