.sellerCard {
    border-radius: 10px !important;
    box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.1);
    > div {
      padding: 25px !important;
      .bestSeller {
        display: flex;
        justify-content: center;
        > p {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .profileDiv {
        margin: 15px 0;
        .sellerDetails {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          .imgDiv {
            .imgProfile {
              display: flex;
              justify-content: space-between;
              align-items: center;
              > img {
                width: 50px;
                border-radius: 50%;
                height: 50px;
              }
              .sellerName {
                > p {
                  margin: 0 0 0 10px;
                  font-weight: 500;
                }
                .sellerTxt {
                  font-size: 14px;
                }
                .selleerEarn {
                  color: gray;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .viewProfile {
          > p {
            color: #43aab2;
            font-weight: 600;
          }
        }
      }
    }
  }
  