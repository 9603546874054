#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.sidebarMain {
  height: 100vh;
  padding: 0 !important;
  @media (max-width: 425px) {
    position: absolute !important;
    z-index: 11111 !important;
  }

  > div {
    background-color: #1e263d;
  }
}
.menuItem {
  background-color: #1e263d !important ; 
  .ant-menu-sub{
    background: #1e263d !important;
     }
}
.ant-menu-item {
  .ant-menu-item-selected {
    background-color: #d7dbe7 !important;
  }
}
.contentMain {
  background-color: #f4f6f9;
  height: 92vh;
  overflow-y: auto;
}
.headerToagle {
  background-color: #fff;
  box-shadow: 0px 16px 12px -7px rgba(0, 0, 0, 0.16) !important;
  > span {
    padding: 10px;
    color: #fff;
    background-color: #1e263d;
  }
}
.logoName {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  > p {
    color: #fff;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
  }
}
.selectOption {
  > div {
    padding: 3px 20px !important;
    height: auto !important;
    border-radius: 18px !important;
    width: 100% !important;
  }
}
.inputIcon {
  border-radius: 20px !important;
  width: 180px !important;
  @media (max-width: 1440px) {
    width: 220px !important;
  }
  @media (max-width: 425px) {
    width: 150px !important;
  }

  @media (max-width: 375px) {
    width: 120px !important;
  }
}
.settingIconDiv {
  margin-top: 5px;
  .settingIcon {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}
.profileImgDiv {
  display: flex;
  align-items: center;
  .profileImg {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
}

